<template>
  <b-card class="mb-0">
    <validation-observer ref="vo">
      <b-form @submit.prevent>
        <b-card-title>
          {{ $t('Users') }}
          <feather-icon
            icon="InfoIcon"
            role="button"
            size="20"
            style="vertical-align: top"
            class="ml-25 text-primary"
            @click.stop="setIsInfo(['users', 'description'])"
          />
        </b-card-title>
        <b-collapse v-model="isInfo.users.description">
          <small class="d-block mb-2">
            {{ $t('infoUsers') }}
          </small>
        </b-collapse>
        <b-row>
          <b-col>
            <validation-provider
              #default="{ errors, failedRules }"
              name="email_user"
              rules="required|email"
            >
              <b-form-group
                :label="$t('usersLabel')"
                class="mb-0"
              >
                <b-input-group class="input-group-merge">
                  <b-input-group-prepend
                    v-if="isFetching || isSendingInvite"
                    is-text
                  >
                    <b-spinner
                      variant="primary"
                      small
                    />
                  </b-input-group-prepend>
                  <b-form-input
                    v-model="userEmail"
                    class="input-invite"
                    :placeholder="$t('usersPlaceholder')"
                    :state="isErrorVisible && errors.length ? false : null"
                    @input="isErrorVisible = false"
                  />
                  <b-input-group-append>
                    <b-dropdown
                      v-model="userRole"
                      :text="$t(`_usersRole.${userRole}`)"
                      variant="outline-primary"
                      right
                    >
                      <b-dropdown-item
                        v-for="role in roles"
                        :key="role"
                        @click="userRole = role"
                      >
                        {{ $t(`_usersRole.${role}`) }}
                      </b-dropdown-item>
                    </b-dropdown>
                  </b-input-group-append>
                </b-input-group>
                <small
                  v-if="isErrorVisible"
                  class="text-danger"
                >
                  <template v-if="failedRules.email">{{ $t('validationErrorEmailUser') }}</template>
                  <template v-else>{{ errors[0] }}</template>
                </small>
              </b-form-group>
            </validation-provider>
          </b-col>
          <b-col cols="auto">
            <div class="mt-2 pt-50">
              <b-button
                id="button-add-user"
                href="#"
                variant="primary"
                :disabled="isFetching || isSendingInvite"
                @click="validateForm"
              >
                {{ $t('usersButton') }}
              </b-button>
              <b-tooltip
                v-if="isSubscriptionInactive || isAddUserDisabled"
                target="button-add-user"
                triggers="focus"
              >
                <div class="pt-50 pb-50">
                  <span v-if="isSubscriptionInactive">{{ $t('tooltipUsersSubscribe') }}</span>
                  <span v-if="isAddUserDisabled">{{ $t('tooltipUsersUpgrade') }}</span>
                  <b-button
                    block
                    size="sm"
                    class="mt-50"
                    variant="primary"
                    @click="$router.push({ name: 'billing' })"
                  >
                    {{ isSubscriptionInactive ? $t('Start Free Trial') : $t('Upgrade') }}
                  </b-button>
                </div>
              </b-tooltip>
            </div>
          </b-col>
        </b-row>
      </b-form>
      <b-row
        v-for="(user, userIndex) in users"
        :key="user.id"
        class="mt-2 align-items-center"
      >
        <b-col>
          <b-form-group
            v-if="userIndex === 0"
            :label="$t('usersCurrentLabel')"
            class="mb-0"
          />
          <div class="d-flex align-items-center">
            <div
              class="user-image"
              :style="{ backgroundImage: `url(${user.photo || require('../assets/images/user.svg')})` }"
            />
            <div class="ml-75">
              <template v-if="user.userFirstName || user.userLastName">
                {{ user.userFirstName }} {{ user.userLastName }}
              </template>
              <span
                v-else
                class="text-muted"
              >
                {{ user.userEmail }} ({{ $t(`_usersStatus.${user.status}`) }})
              </span>
            </div>
          </div>
        </b-col>
        <b-col
          cols="auto"
          :style="{ paddingTop: userIndex === 0 ? '27px' : null }"
        >
          <div class="d-flex align-items-center">
            {{ $t(`_usersRole.${user.role.toLowerCase()}`) }}
            <feather-icon
              icon="XCircleIcon"
              role="button"
              size="20"
              :class="[
                'flex-shrink-0 ml-75',
                { 'feather-disabled': user.role.toLowerCase() === 'owner' || user.status === 'currentUser' },
              ]"
              @click="confirmDeleteUser(user.id)"
            />
          </div>
        </b-col>
      </b-row>
    </validation-observer>
    <modal-confirm-delete
      v-model="isModalConfirmDelete"
      @delete="onDeleteUser"
    />
  </b-card>
</template>

<script>
import {
  BFormInput,
  BCard,
  BCardTitle,
  BForm,
  BFormGroup,
  BButton,
  BRow,
  BCol,
  BInputGroup,
  BInputGroupPrepend,
  BInputGroupAppend,
  BDropdown,
  BDropdownItem,
  VBTooltip,
  BTooltip,
  BSpinner,
  BCollapse,
} from 'bootstrap-vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required, email } from '@validations'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { mapGetters, mapActions } from 'vuex'
import ModalConfirmDelete from '@/components/modal/ModalConfirmDelete.vue'

import GET_USERS_INVITED_TO_ORGANIZATION from '@/gql/query/getUsersInvitedToOrganization.gql'
import CREATE_INVITATION_TO_ORGANIZATION from '@/gql/mutation/invitation/createInvitationToOrganization.gql'
import DELETE_INVITED_USER_FROM_ORGANIZATION from '@/gql/mutation/invitation/deleteInvitedUserFromOrganization.gql'

export default {
  name: 'Users',
  components: {
    BFormInput,
    BCard,
    BCardTitle,
    BForm,
    BFormGroup,
    BDropdown,
    BDropdownItem,
    BButton,
    ValidationProvider,
    ValidationObserver,
    BRow,
    BCol,
    ModalConfirmDelete,
    BInputGroup,
    BInputGroupPrepend,
    BInputGroupAppend,
    BTooltip,
    BSpinner,
    BCollapse,
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  data() {
    return {
      userEmail: '',
      userRole: 'admin',
      roles: ['admin'],

      users: [],

      userIdToDelete: null,
      isModalConfirmDelete: false,

      isSendingInvite: false,
      isFetching: true,
      isErrorVisible: false,

      // validations
      required,
      email,
    }
  },
  computed: {
    ...mapGetters({
      isInfo: 'main/isInfo',
      subscription: 'billing/subscription',
      isSubscriptionInactive: 'billing/isSubscriptionInactive',
      isSubscriptionActive: 'billing/isSubscriptionActive',
      isSubscriptionCanceled: 'billing/isSubscriptionCanceled',
      restaurant: 'restaurant/restaurant',
    }),
    isAddUserDisabled() {
      return this.subscription
        && (this.isSubscriptionActive || this.isSubscriptionCanceled)
        && this.users.length >= this.subscription.productDetails.restrictions.collaboratorsAmount
    },
  },
  watch: {
    restaurant() {
      this.fetch()
    },
  },
  created() {
    this.fetch()
  },
  methods: {
    ...mapActions({
      setIsInfo: 'main/setIsInfo',
    }),
    confirmDeleteUser(userId) {
      this.userIdToDelete = userId
      this.isModalConfirmDelete = true
    },

    async fetch() {
      if (!this.restaurant) {
        return
      }

      const response = await this.$apollo.query({
        query: GET_USERS_INVITED_TO_ORGANIZATION,
        variables: {
          organizationId: this.restaurant.organization.id,
        },
      })

      // eslint-disable-next-line no-undef
      this.users = structuredClone(response.data.getUsersInvitedToOrganization)

      this.isFetching = false
    },
    validateForm() {
      this.$refs.vo.validate().then(async isValid => {
        if (this.isSubscriptionInactive || this.isAddUserDisabled) {
          return
        }

        if (!isValid) {
          this.isErrorVisible = true
          return
        }

        if (this.users.find(i => i.userEmail === this.userEmail)) {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: this.$t('Error'),
              text: this.$t('notificationErrorUserInList'),
              icon: 'XCircleIcon',
              variant: 'danger',
            },
          })
          return
        }

        try {
          this.isSendingInvite = true

          const response = await this.$apollo.mutate({
            mutation: CREATE_INVITATION_TO_ORGANIZATION,
            variables: {
              organization: this.restaurant.organization.id,
              role: this.userRole,
              userEmail: this.userEmail,
            },
          })

          // eslint-disable-next-line no-undef
          this.users = [structuredClone(response.data.createInvitationToOrganization), ...this.users]

          this.$toast({
            component: ToastificationContent,
            props: {
              title: this.$t('Success'),
              icon: 'CheckCircleIcon',
              variant: 'success',
            },
          })
          this.userEmail = ''
          this.$refs.vo.reset()
        } catch (error) {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: this.$t('Error'),
              text: Array.isArray(error.graphQLErrors[0].message)
                ? error.graphQLErrors[0].message[0]
                : error.graphQLErrors[0].message,
              icon: 'XCircleIcon',
              variant: 'danger',
            },
          })
        } finally {
          this.isSendingInvite = false
        }
      })
    },
    async onDeleteUser() {
      try {
        await this.$apollo.mutate({
          mutation: DELETE_INVITED_USER_FROM_ORGANIZATION,
          variables: {
            invitationId: this.userIdToDelete,
          },
        })

        this.users = this.users.filter(i => i.id !== this.userIdToDelete)

        this.$toast({
          component: ToastificationContent,
          props: {
            title: this.$t('Success'),
            text: this.$t('notificationSuccessDeleted'),
            icon: 'CheckCircleIcon',
            variant: 'success',
          },
        })
      } catch (error) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: this.$t('Error'),
            text: Array.isArray(error.graphQLErrors[0].message)
              ? error.graphQLErrors[0].message[0]
              : error.graphQLErrors[0].message,
            icon: 'XCircleIcon',
            variant: 'danger',
          },
        })
      } finally {
        this.userIdToDelete = null
        this.isModalConfirmDelete = false
      }
    },
  },
}
</script>

<style lang="sass">
  .input-group
    .input-invite
      &:focus
        z-index: auto

  .user-image
    width: 26px
    height: 26px
    border-radius: 50%
    background-position: center
    background-size: cover
    flex-shrink: 0
</style>
